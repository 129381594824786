import {SafeUrl} from "@angular/platform-browser";

export class Bonus {
  constructor(
    public articleCode: string,
    public name: string,
    public description: string,
    public priceAmount: number,
    public portoAmount: number,
    public userAdjustment: number,
    public availableFlag: boolean,
    public bonusValue: number,
    public hasImage: boolean,
    public img: SafeUrl,
    public isoCurrencyCode: string,
    public quantity: number

    /*
        articleTaxId: number,
        available: boolean,
        description: string,
        id: string,
        sortKey: number,
        supplierId: string,
        taxIncluded: boolean,
        validDate: {
          validFrom: Date,
          validUntil: Date
        }
    */
  ) {
  }
}
