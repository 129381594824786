import {Injectable} from "@angular/core";
import {Observable, of, throwError} from "rxjs";
import {ImageModel} from "../../data/models/image.model";
import {catchError, map} from "rxjs/operators";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ImageService {
  private offerGroupImages: ImageModel[] = [];
  private offerImages: ImageModel[] = []

  constructor(private http: HttpClient) {
  }

  getImageOfOfferGroup(offerGroup: number): Observable<ImageModel> {
    if (this.offerGroupImages.length > 0) {
      return of(this.offerGroupImages[offerGroup]);
    }
    return this.http.get<any>('/webabo/offers/groups/' + offerGroup + '/images')
      .pipe(
        catchError((error => {
          console.log("Error while loading offerGroupImage for OfferGroup: " + offerGroup)
          return of(null);
        })),
        map(image => {
          if (image) {
            const imageModel = this.getImageForResult(image);
            this.offerGroupImages[offerGroup] = imageModel;
            return imageModel;
          }
        }));
  }

  getImageOfOffer(offer: number): Observable<ImageModel> {
    //if (this.offerImages.length > 0 && this.offerImages[offer] && this.offerImages[offer] !== undefined && this.offerImages[offer]?.type && this.offerImages[offer]?.file) {
    //  return of(this.offerImages[offer]);
    //}
    return this.http.get<any>('/webabo/offers/' + offer + '/images').pipe(
      catchError((error => {
        console.log("Error while loading offerImage for OfferID: " + offer)
        return of(null);
      })),
      map(image => {
        const resultImage = this.getImageForResult(image);
        this.offerImages[offer] = resultImage;
        return resultImage;
      }));
  }

  getConfirmationImageOfOffer(offer: number): Observable<ImageModel> {
    if (this.offerImages.length > 0) {
      return of(this.offerImages[offer]);
    }
    return this.http.get<any>('/webabo/offers/' + offer + '/images').pipe(
      catchError((error => {
        console.log("Error while loading offerImage for OfferID: " + offer)
        return of(null);
      })),
      map(image => {
        const resultImage = this.getImageForResult(image);
        this.offerImages[offer] = resultImage;
        return resultImage;
      }));
  }

  private getImageForResult(image: any) {
    if (image) {
      const imageModel = new ImageModel(image.type, image.file);
      return imageModel;
    } else {
      return new ImageModel(null, null);
    }
  }

  getImageOfArticle(articleId: string): Observable<ImageModel> {
    return this.http.get<any>('/webabo/articles/' + articleId + '/images').pipe(
      catchError(( error => {
        console.log("Error while loading image for articleID: " + articleId)
        return of(null);
      })),
      map( image => {
        return this.getImageForResult(image);
      })
    )
  }
}
