<div *ngIf="!offer || isLoadingView" style="text-align: center;" id="offerSpinner">
  <app-loading-spinner></app-loading-spinner>
</div>
<div class="offerContainer" #offerContainer *ngIf="offer && !isLoadingView" (window:resize)="onResize($event)">
    <app-general-errors [errors]="errorsArr"></app-general-errors>

    <div class="row">
        <div class="col-12 col-md-8">
            <mat-stepper [linear]="true" #stepper [orientation]="stepperOrientation" labelPosition="bottom">
                <!-- Upselling -->
                <mat-step [stepControl]="sixthFormGroup" [editable]="isEditable"
                          *ngIf="false && offer.combinedOfferList">
                    <form [formGroup]="fifthFormGroup" class="mt-3">
                        <ng-template matStepLabel><div [innerHTML]="translationService.getTranslation('step_upselling')"></div></ng-template>
                        <div *ngFor="let combinedOffer of offer.combinedOfferList">
                            <app-offer-card [offer]="combinedOffer"></app-offer-card>
                        </div>

                        <div class="float-end mt-3">
                            <button mat-raised-button color="primary"
                                    matStepperNext [innerHTML]="translationService.getTranslation('button_next')"></button>
                        </div>
                    </form>

                </mat-step>

                <!-- Registrierung (opt.) -->
                <mat-step [stepControl]="fifthFormGroup" [editable]="isEditable" *ngIf="!isAuthenticated && !hideLoginBox">
                    <form [formGroup]="fifthFormGroup" class="mt-3">
                        <ng-template
                                matStepLabel><div [innerHTML]="translationService.getTranslation('step_orderRegister')"></div></ng-template>
                        <mat-card appearance="outlined" class="mt-3">
                            <mat-card-content>
                                <div class="row">
                                    <div class="col-12"
                                         [innerHTML]="translationService.getTranslation('account_already_exists_description')"></div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                        <mat-card appearance="outlined" class="mt-3" *ngIf="!hideLoginBox">
                            <mat-card-content>
                                <div class="row">
                                    <div class="col-12"
                                         [innerHTML]="translationService.getTranslation('account_already_exists')"></div>
                                    <div class="col-12">
                                        <div class="mt-3">
                                            <button mat-raised-button color="primary" (click)="onClickEvent()" type="button">
                                                <app-login #login></app-login>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>

                        <mat-card appearance="outlined" class="mt-3">
                            <mat-card-content>
                                <div class="row">
                                    <div class="col-12 pl-15"
                                         [innerHTML]="translationService.getTranslation('account_register')">
                                    </div>
                                </div>
                                <mat-form-field class="col-12 mt-3">
                                    <mat-label [innerHTML]="translationService.getTranslation('register_email')"></mat-label>
                                    <input matInput formControlName="email" required
                                           [value]="fifthFormGroup.get('email').value"
                                           [class.is-valid]="fifthFormGroup.get('email').valid">
                                    <mat-spinner class="iconInInput" *ngIf="fifthFormGroup.get('email').pending"
                                                 matSuffix [diameter]="18"
                                                 style="float: right; margin-left: 18px"></mat-spinner>
                                    <mat-icon class="iconInInput" *ngIf="fifthFormGroup.get('email').valid" matSuffix>
                                        check
                                    </mat-icon>
                                    <mat-error
                                            *ngIf="fifthFormGroup.get('email').hasError('message')">{{fifthFormGroup.get('email').getError('message')}}</mat-error>
                                </mat-form-field>
                            </mat-card-content>
                        </mat-card>

                        <div class="float-end mt-3">
                            <button mat-raised-button color="primary" type="submit"
                                    matStepperNext [innerHTML]="translationService.getTranslation('button_next')"></button>
                        </div>
                    </form>
                </mat-step>
                <!-- Prämien (opt.) -->
                <mat-step [stepControl]="firstFormGroup" [editable]="isEditable" *ngIf="bonusSelection">
                    <form [formGroup]="firstFormGroup" class="mt-3">

                        <ng-template matStepLabel> <div [innerHTML]="translationService.getTranslation('step_orderBonus')"></div></ng-template>

                        <div class="container-fluid p-0">
                            <div class="row">
                                <div class="col-12">
                                    <div id="bonusCard">
                                        <div class="row">
                                            <ng-container *ngFor="let bonus of bonuses; let i = index">
                                                <ng-container *ngIf="i < maxBonuses">
                                                  <div [className]="
                                                        'col-' + (CssPropertiesEnum.bonusFxFlex|cssProperty: vHostService) + ' ' +
                                                        'col-sm-' + (CssPropertiesEnum.bonusFxFlexSM|cssProperty: vHostService) + ' ' +
                                                        'col-md-' + (CssPropertiesEnum.bonusFxFlexMD|cssProperty: vHostService) + ' ' +
                                                        'col-lg-' + (CssPropertiesEnum.bonusFxFlexLG|cssProperty: vHostService) + ' ' +
                                                        'col-xl-' + (CssPropertiesEnum.bonusFxFlexXL|cssProperty: vHostService) + ' ' +
                                                         'col-xxl-' + (CssPropertiesEnum.bonusFxFlexXXL|cssProperty: vHostService)">
                                                        <mat-card appearance="outlined"
                                                                  class="mat-elevation-z4 pointer h-100">
                                                            <div class="bonusBody h-100"
                                                                 (click)="onSelectBonus(bonus, bonusNextButton)">
                                                                <img class="mw-100" mat-card-image
                                                                     *ngIf="bonus.hasImage"
                                                                     [src]="bonus.img" [alt]="bonus.name">
                                                                <span class="h-100"
                                                                      *ngIf="!bonus.hasImage">{{bonus.name}}</span>
                                                            </div>
                                                            <div class="footer">
                                                                <button class="w-100" mat-raised-button color="primary"
                                                                        (click)="onShowDetails(bonus)" [innerHTML]="translationService.getTranslation('button_details')"></button>
                                                            </div>
                                                        </mat-card>
                                                    </div>
                                                </ng-container>
                                            </ng-container>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-12 mt-3 mb-5 text-center" *ngIf="bonuses.length > maxBonuses">
                                    <button mat-raised-button color="primary"
                                            (click)="loadMoreBonuses()" [innerHTML]="translationService.getTranslation('button_more_bonuses')"></button>
                                </div>
                            </div>
                        </div>

                        <div class="col-12 mt-1">
                            <div *ngFor="let bonus of getBonusControls(); let i = index">
                                <mat-checkbox (click)="onDeselectBonus(i)" checked="checked" class="text-wrap">
                                    <div [innerHTML]="bonus.value.name"></div>
                                </mat-checkbox>
                            </div>
                        </div>

                        <div class="col-12 mt-5" *ngIf="!selectedBonus" [innerHTML]="translationService.getTranslation('offer.missingBonus')">
                        </div>

                        <div class="float-end mt-1" #bonusNextButton>
                            <button class="me-3" mat-raised-button
                                    matStepperPrevious
                                    *ngIf="!isAuthenticated" [innerHTML]="translationService.getTranslation('button_back')"></button>
                            <button align="end" mat-raised-button color="primary"
                                    matStepperNext [innerHTML]="translationService.getTranslation('button_next')"></button>
                        </div>
                    </form>
                </mat-step>

                <!-- Adressen -->
                <mat-step [stepControl]="secondFormGroup" [editable]="isEditable">
                    <form [formGroup]="secondFormGroup" class="mt-3">
                        <ng-template matStepLabel><div [innerHTML]="translationService.getTranslation('step_orderAddr')"></div> </ng-template>
                        <ng-container
                                *ngIf="offer.requiredAddresses.deliveryAddressRequired && offer.requiredAddresses.billingAddressRequired">
                            <app-hup-subscription-address [showActivateCheckbox]="false"
                                                          [askForBirthday]="askForBirthday"
                                                          [controlGroup]="secondFormGroup.get('deliveryAddress')"
                                                          *ngIf="offer.requiredAddresses.deliveryAddressRequired"></app-hup-subscription-address>
                            <ng-container *ngIf="!onlyOneAddress">
                                <app-hup-subscription-address [showActivateCheckbox]="true"
                                                            [controlGroup]="secondFormGroup.get('billingAddress')"
                                                            activateCheckboxText="address_diffBilling"
                                                            *ngIf="offer.requiredAddresses.billingAddressRequired"></app-hup-subscription-address>
                            </ng-container>
                        </ng-container>

                        <ng-container
                                *ngIf="!offer.requiredAddresses.deliveryAddressRequired && offer.requiredAddresses.billingAddressRequired">
                            <app-hup-subscription-address [showActivateCheckbox]="false"
                                                          [askForBirthday]="askForBirthday"
                                                          [controlGroup]="secondFormGroup.get('billingAddress')"
                                                          activateCheckboxText="address_diffBilling"
                                                          *ngIf="offer.requiredAddresses.billingAddressRequired"></app-hup-subscription-address>

                        </ng-container>

                        <ng-container
                                *ngIf="offer.requiredAddresses.deliveryAddressRequired && !offer.requiredAddresses.billingAddressRequired">
                            <app-hup-subscription-address [showActivateCheckbox]="false"
                                                          [askForBirthday]="askForBirthday"
                                                          [controlGroup]="secondFormGroup.get('deliveryAddress')"
                                                          *ngIf="offer.requiredAddresses.deliveryAddressRequired"></app-hup-subscription-address>
                        </ng-container>

                        <app-hup-subscription-address [showActivateCheckbox]="true"
                                                      [controlGroup]="secondFormGroup.get('bonusAddress')"
                                                      activateCheckboxText="address_diffBonus"
                                                      *ngIf="bonusSelection && !onlyOneAddress"></app-hup-subscription-address>

                      <mat-form-field *ngIf="hideLoginBox && !isWinback" class="col-12 ps-2">
                        <mat-label [innerHTML]="translationService.getTranslation('register_email')"></mat-label>
                        <input matInput formControlName="email" required
                               [value]="secondFormGroup.get('email').value"
                               [class.is-valid]="secondFormGroup.get('email').valid">
                        <mat-spinner class="iconInInput" *ngIf="secondFormGroup.get('email').pending"
                                     matSuffix [diameter]="18"
                                     style="float: right; margin-left: 18px"></mat-spinner>
                        <mat-icon class="iconInInput" *ngIf="secondFormGroup.get('email').valid" matSuffix>
                          check
                        </mat-icon>
                        <mat-error
                          *ngIf="secondFormGroup.get('email').hasError('message')">{{secondFormGroup.get('email').getError('message')}}</mat-error>
                      </mat-form-field>

                        <div class="float-end mt-1">
                            <button class="me-3" mat-raised-button
                                    matStepperPrevious *ngIf="!userData" [innerHTML]="translationService.getTranslation('button_back')"></button>
                            <button mat-raised-button color="primary" matStepperNext
                                    (click)="onSelectAddresses();setStartDate();setUniquePaymentInfos();" [innerHTML]="translationService.getTranslation('button_next')"></button>
                        </div>
                    </form>
                </mat-step>

                <!-- Zahlart -->
                <mat-step [stepControl]="thirdFormGroup" [editable]="isEditable">
                    <form [formGroup]="thirdFormGroup" class="mt-3">
                        <ng-template
                                matStepLabel> <div [innerHTML]="translationService.getTranslation('step_orderPayment')"></div> </ng-template>


                        <ng-container formArrayName="extendedData">
                          <div *ngFor="let field of getAdditionalDataControls(); let i = index">
                            <mat-form-field class="col-12">
                              <mat-label>{{additionalData[i].description}}</mat-label>
                              <input matInput [formControlName]="i"
                                     [value]="field.value">
                              <mat-error
                                *ngIf="field.hasError('message')">{{field.getError('message')}}</mat-error>
                            </mat-form-field>
                          </div>
                        </ng-container>

                        <mat-form-field class="col-12">
                            <mat-label [innerHTML]="translationService.getTranslation('payment_type')"></mat-label>
                            <mat-select formControlName="formOfPayment" required
                                        [value]="thirdFormGroup.get('formOfPayment').value">
                                <ng-container *ngFor="let formOfPayment of formsOfPayment">
                                    <mat-option [value]="formOfPayment">{{formOfPayment.description}}</mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-error
                                    *ngIf="thirdFormGroup.get('formOfPayment').hasError('message')">{{thirdFormGroup.get('formOfPayment').getError('message')}}</mat-error>
                        </mat-form-field>

                        <mat-form-field class="col-12">
                            <mat-label [innerHTML]="translationService.getTranslation('payment_rhythm')"></mat-label>
                            <mat-select formControlName="paymentFrequency" required
                                        [value]="thirdFormGroup.get('paymentFrequency').value">
                                <ng-container *ngFor="let paymentFrequency of paymentFrequencies">
                                    <mat-option *ngIf="paymentFrequency.useInFE"
                                                [value]="paymentFrequency">{{paymentFrequency.description}}</mat-option>
                                </ng-container>
                            </mat-select>
                            <mat-error
                                    *ngIf="thirdFormGroup.get('paymentFrequency').hasError('message')">{{thirdFormGroup.get('paymentFrequency').getError('message')}}</mat-error>
                        </mat-form-field>

                        <ng-container *ngIf="needIbanBic">
                            <mat-form-field class="col-12">
                                <mat-label [innerHTML]="translationService.getTranslation('payment_iban')"></mat-label>
                                <input matInput formControlName="iban" [value]="thirdFormGroup.get('iban').value"
                                       oninput="this.value = this.value.toUpperCase()">
                                <mat-error
                                        *ngIf="thirdFormGroup.get('iban').hasError('message')">{{thirdFormGroup.get('iban').getError('message')}}</mat-error>
                            </mat-form-field>
                            <div *ngIf="thirdFormGroup.get('iban').status === 'PENDING'">
                                <app-loading-spinner [type]="'small'"></app-loading-spinner>
                            </div>

                            <mat-form-field class="col-12">
                                <mat-label [innerHTML]="translationService.getTranslation('payment_bic')"></mat-label>
                                <input matInput formControlName="bic" [value]="thirdFormGroup.get('bic').value">
                                <mat-error
                                        *ngIf="thirdFormGroup.get('bic').hasError('message')">{{thirdFormGroup.get('bic').getError('message')}}</mat-error>
                            </mat-form-field>
                        </ng-container>

                        <ng-container *ngIf="needInvoiceMailAddress">
                            <mat-form-field class="col-12">
                                <mat-label [innerHTML]="translationService.getTranslation('payment_invoice_email')"></mat-label>
                                <input matInput formControlName="invoiceEmail"
                                       [value]="thirdFormGroup.get('invoiceEmail').value">
                                <mat-error
                                        *ngIf="thirdFormGroup.get('invoiceEmail').hasError('message')">{{thirdFormGroup.get('invoiceEmail').getError('message')}}</mat-error>
                            </mat-form-field>
                        </ng-container>
                        <mat-form-field class="col-12" (click)="picker1.open()">
                            <mat-label [innerHTML]="translationService.getTranslation('startdate')"></mat-label>
                            <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker1" readonly
                                   formControlName="startdate">
                            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                            <mat-datepicker #picker1 disabled="false"></mat-datepicker>
                        </mat-form-field>

                        <ng-container *ngIf="!privacyDisclaimerAgbInOne; else allInOne">
                            <div class="col-12">
                                <mat-checkbox formControlName="agb" required class="text-wrap" required
                                              class="text-wrap" #agbBox>
                                    <div [innerHTML]="translationService.getTranslation('order_agbaccept_epaper')"></div>
                                </mat-checkbox>
                                <mat-error class="alignEnd" *ngIf="!agbBox.checked && checkBoxes"
                                           [innerHTML]="translationService.getTranslation('order_agbaccept_epaper_error')"></mat-error>
                            </div>

                            <div class="col-12">
                                <mat-checkbox formControlName="disclaimer" required class="text-wrap" #disclaimerBox>
                                    <div [innerHTML]="translationService.getTranslation('order_widerrufInfo')"></div>
                                </mat-checkbox>
                                <mat-error class="alignEnd" *ngIf="!disclaimerBox.checked && checkBoxes"
                                           [innerHTML]="translationService.getTranslation('order_widerrufInfo_error')"></mat-error>
                            </div>

                            <div class="col-12">
                                <mat-checkbox formControlName="privacy" required class="text-wrap" #privacyBox>
                                    <div [innerHTML]="translationService.getTranslation('order_privacyInfo')"></div>
                                </mat-checkbox>
                                <mat-error class="alignEnd" *ngIf="!privacyBox.checked && checkBoxes"
                                           [innerHTML]="translationService.getTranslation('order_privacyInfo_error')"></mat-error>
                            </div>

                        </ng-container>
                        <ng-template #allInOne >
                            <div class="col-12">
                                <mat-checkbox formControlName="privacyDisclaimerAgb" required class="text-wrap" required
                                              class="text-wrap" #agbBox>
                                    <div [innerHTML]="translationService.getTranslation('order_privacyDisclaimerAgb')"></div>
                                </mat-checkbox>
                                <mat-error class="alignEnd" *ngIf="!agbBox.checked && checkBoxes"
                                           [innerHTML]="translationService.getTranslation('order_privacyDisclaimerAgb_error')"></mat-error>
                            </div>
                        </ng-template>
                        <div class="float-end mt-1">
                            <button class="me-3" mat-raised-button
                                    matStepperPrevious [innerHTML]="translationService.getTranslation('button_back')"></button>
                            <button mat-raised-button color="primary" matStepperNext
                                    (click)="calcPriceOfSubscription()" [innerHTML]="translationService.getTranslation('button_check_price')"></button>

                        </div>
                    </form>
                </mat-step>

                <!-- Preis -->
                <mat-step>
                    <ng-template matStepLabel><div [innerHTML]="translationService.getTranslation('step_check')"></div> </ng-template>
                    <ng-container *ngIf="calculatingPrice">
                        <div class="text-center">
                            <h5 [innerHTML]="translationService.getTranslation('wait_while_pricecalculation')"></h5>
                            <app-loading-spinner [type]="'small'"></app-loading-spinner>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!calculatingPrice">
                        <div class="text-center">
                            <h5 class="text-center" [innerHTML]="translationService.getTranslation(hasError ? 'error_price_calculation' : 'price_calculated')"></h5>
                        </div>
                    </ng-container>
                  <ng-container *ngIf="creatingOrder">
                    <div class="text-center">
                      <h5 [innerHTML]="translationService.getTranslation('wait_while_orderCreating')"></h5>
                      <app-loading-spinner [type]="'small'"></app-loading-spinner>
                    </div>
                  </ng-container>
                    <ng-container *ngIf="showOnlinePayment">
                        <app-onlinepayment></app-onlinepayment>
                    </ng-container>
                </mat-step>

            </mat-stepper>
        </div>
        <div class="col-12 col-md-4">
            <app-offer-card
                    [offer]="offer"
                    [showOnOfferPage]="false"
                    [stepper]="stepper"
                    [bonus]="selectedBonus"
                    [deliveryAddress]="selectedDeliveryAddress"
                    [billingAddress]="selectedBillingAddress"
                    [bonusAddress]="selectedBonusAddress"
                    [paymentCode]="selectedFormOfPayment"
                    [paymentFrequency]="selectedPaymentFrequency"
                    [startDate]="selectedStartDate"
                    [prices]="offerPrice"
                    [creatingOrder]="creatingOrder"
                    [onlinePayment]="showOnlinePayment"
                    (save)="onCompleteOrder($event)"
            ></app-offer-card>
        </div>
    </div>
</div>

<div class="mt-3 float-end">
    <button *ngIf="showBackToOverviewButton && !isLoadingView" mat-raised-button
            (click)="backToOverview()" [innerHTML]="translationService.getTranslation('button_back_to_overview')"></button>
</div>
