<div class="row" *ngIf="sub">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('subscription_header')"></h4>
  </div>
  <div class="col-12 mb-5" [innerHTML]="translationService.getTranslation('subscription_intro')">
  </div>

  <div class="col-12 mb-5" *ngIf="sub.state === 3"
       [innerHTML]="translationService.getTranslation('subscription_canceled')">
  </div>
  <div class="form-group col-12">
    <div class="row">
      <div class="col-6" [innerHTML]="translationService.getTranslation('label_backendid')">
      </div>
      <div class="col-6">
        {{ sub.userBackendId }}
      </div>
    </div>
  </div>
  <div class="form-group col-12">
    <div class="row">
      <div class="col-6" [innerHTML]="translationService.getTranslation('order_id')">
      </div>
      <div class="col-6" *ngIf="sub.backendId <= 0" [innerHTML]="translationService.getTranslation('no_order_id')">
      </div>
      <div class="col-6" *ngIf="sub.backendId > 0">
        {{ sub.backendId }}
      </div>
    </div>
  </div>
  <div class="form-group col-12">
    <div class="row">
      <div class="col-6" [innerHTML]="translationService.getTranslation('period')">
      </div>
      <div class="col-6">
        {{ sub.validDate.validFrom | date: translationService.getTranslation('dateFormat') }} -
        {{ (sub.validDate.validUntil) ? (sub.validDate.validUntil | date: translationService.getTranslation('dateFormat')) : translationService.getTranslation('subscription_endless') }}
      </div>
    </div>
  </div>
  <div class="form-group col-12" *ngIf="sub.commitmentDate && sub.commitmentDate > today">
    <div class="row">
      <div class="col-6" [innerHTML]="translationService.getTranslation('commitmentDate')">
      </div>
      <div class="col-6">
        {{ sub.commitmentDate | date: translationService.getTranslation('dateFormat') }}
      </div>
    </div>
  </div>
  <ng-container *ngIf="showAdditionalSubscriptionInfos">
    <div class="form-group col-12" *ngIf="sub.curPayment">
      <div class="row">
        <div class="col-6" [innerHTML]="translationService.getTranslation('payment_bankinfo')">
        </div>
        <div class="col-6">
          <ul class="list-unstyled">
            <li>
              {{ sub.curPayment.paymentType.paymentMethod }}
            </li>
            <li>
              {{ sub.curPayment.paymentType.paymentFrequency }}
            </li>
            <ng-container *ngIf="paymentMethodId && paymentMethodId.paymentType === 'DIRECTDEBITIBAN'">
              <li>
                {{ translationService.getTranslation('payment_iban') }} {{ sub?.curPayment?.bank?.swiftIBAN | bankAccount }}
              </li>
              <li>
                {{ translationService.getTranslation('payment_bic') }} {{ sub?.curPayment?.bank?.swiftBIC }}
              </li>
            </ng-container>
          </ul>
        </div>
      </div>
    </div>
    <div class="form-group col-12">
      <div class="row">
        <div class="col-6" [innerHTML]="translationService.getTranslation('order_product')">
        </div>
        <div class="col-6">
          {{ sub.productDescription }}
        </div>
      </div>
    </div>
    <ng-container *ngIf="sub.variantDescription">
      <div class="form-group col-12">
        <div class="row">
          <div class="col-6" [innerHTML]="translationService.getTranslation('order_edition')">
          </div>
          <div class="col-6">
            {{ sub.variantDescription }}
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="sub.curDelivery">
      <div class="form-group col-12">
        <div class="row">
          <div class="col-6" [innerHTML]="translationService.getTranslation('address_delivery')">
          </div>
          <div class="col-6">
            <app-address-detail [address]="sub.curDelivery.address"
                                [person]="sub.curDelivery.person"></app-address-detail>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="sub.curBilling">
      <div class="form-group col-12">
        <div class="row">
          <div class="col-6" [innerHTML]="translationService.getTranslation('address_billing')">
          </div>
          <div class="col-6">
            <app-address-detail [address]="sub.curBilling.address"
                                [person]="sub.curBilling.person"></app-address-detail>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <div class="form-group col-12" *ngIf="faqButtonLink">
    <div class="row">
      <div class="col-6">

      </div>
      <div class="col-6">
        <button mat-raised-button color="primary"
                href="{{faqButtonLink}}">{{ translationService.getTranslation('subscription_faqButton') }}
        </button>
      </div>
    </div>
  </div>

  <ng-container *ngIf="sub.subId > 0 && sub.state !== 3 && showTerminationButton">
    <div class="form-group col-12">
      <div class="row">
        <div class="col-6" [innerHTML]="translationService.getTranslation('terminate_subscription')">
        </div>
        <div class="col-6">
          <button mat-raised-button
                  (click)="terminateSubscription()" [innerHTML]="translationService.getTranslation('button_terminate')">
          </button>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div class="mt-3 float-end">
  <button *ngIf="showBackToOverviewButton"
          mat-raised-button (click)="backToOverview()" [innerHTML]="translationService.getTranslation('button_back_to_overview')"></button>
</div>
