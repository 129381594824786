<div class="offerView" *ngIf="actualPage">
  <img [src]="actualPage.viewImageUrl">

  <div class="offerViewDescription" [innerHtml]="actualPage.viewDescription">
  </div>
</div>

<div class="row" [className]="CssPropertiesEnum.offerHeaderClasses|cssProperty: vHostService" id="offerHeader">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('offer_intro')"></h4>
  </div>
  <div class="col-12" [innerHTML]="translationService.getTranslation('offer_description')">
  </div>
</div>
<div class="row mb-3">
  <div class="col-12" id="offerErrors">
    <app-general-errors [errors]="errorsArr"></app-general-errors>
    <div *ngIf="isLoadingOffer" style="text-align: center;" id="offerSpinner">
      <app-loading-spinner></app-loading-spinner>
    </div>
  </div>
</div>

<div class="offerPage" id="offers">
  <div class="row">
    <ng-container *ngFor="let offer of offers">
      <div *ngIf="activeGroup === offer.offerGroup" [className]="
                  'col-' + (CssPropertiesEnum.offerFxFlex|cssProperty: vHostService) + ' ' +
                  'col-sm-' + (CssPropertiesEnum.offerFxFlexSM|cssProperty: vHostService) + ' ' +
                  'col-md-' + (CssPropertiesEnum.offerFxFlexMD|cssProperty: vHostService) + ' ' +
                  'col-lg-' + (CssPropertiesEnum.offerFxFlexLG|cssProperty: vHostService) + ' ' +
                  'col-xl-' + (CssPropertiesEnum.offerFxFlexXL|cssProperty: vHostService) + ' ' +
                  'col-xxl-' + (CssPropertiesEnum.offerFxFlexXXL|cssProperty: vHostService)">
        <app-offer-card [offer]="offer"></app-offer-card>
      </div>
    </ng-container>
  </div>
  <div class="mt-3 float-end">
    <button mat-raised-button
            (click)="goBackToGroupView()" [innerHTML]="translationService.getTranslation('button_back')"></button>
  </div>
</div>

