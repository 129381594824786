<div class="col-12">
  <h4 [innerHTML]="translationService.getTranslation('headline_terminate')"></h4>
</div>
<div class="col-12 mb-5 " [innerHTML]="translationService.getTranslation('terminate_intro')">
</div>

<div *ngIf="isLoading; else terminationFormTemplate" style="text-align: center;">
  <app-loading-spinner></app-loading-spinner>
</div>

<ng-template #terminationFormTemplate>
<ng-container *ngIf="terminationTypes">
  <form [formGroup]="terminationForm">

    <mat-form-field>
      <mat-label [innerHTML]="translationService.getTranslation('terminate_reason')"></mat-label>
      <mat-select formControlName="reason" required [value]="terminationForm.get('reason').value">
        <ng-container *ngFor="let terminationType of terminationTypes">
          <mat-option *ngIf="terminationType.useInFE"
                      [value]="terminationType">{{terminationType.description}}</mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field (click)="picker1.open()">
      <mat-label [innerHTML]="translationService.getTranslation('terminate_date')"></mat-label>
      <input matInput [min]="minDate" [matDatepicker]="picker1" readonly formControlName="terminationDate">
      <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
      <mat-datepicker [touchUi]="false" #picker1 disabled="false"></mat-datepicker>
    </mat-form-field>
    <div>
      <button mat-raised-button
              (click)="terminateSubscription()" [innerHTML]="translationService.getTranslation('button_terminate')"></button>
    </div>
  </form>
</ng-container>

<div class="mt-3 float-end">
  <button *ngIf="showBackToOverviewButton"
          mat-raised-button (click)="backToOverview()" [innerHTML]="translationService.getTranslation('button_back_to_overview')"></button>
</div>
</ng-template>
