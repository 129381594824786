import {AfterViewInit, Component, Inject, OnInit} from '@angular/core';
import {CssPropertiesEnum} from "../../core/models/css-properties.enum";
import {TranslationService} from "../../core/services/translation.service";
import {VHostService} from "../../core/services/v-host.service";
import {OfferGroup} from "../../data/models/offer-group.model";
import {OfferService} from "../../data/services/offer.service";
import {NavigationCommand, NavigationService} from "../../data/services/navigation.service";
import {DOCUMENT} from "@angular/common";

@Component({
  selector: 'app-offer-group',
  templateUrl: './offer-group.component.html',
  styleUrls: ['./offer-group.component.css']
})
export class OfferGroupComponent implements OnInit, AfterViewInit {

  CssPropertiesEnum = CssPropertiesEnum;
  isLoadingOfferGroup: boolean = false;
  errorsArr: string[] = [];
  offerGroups: OfferGroup[] = [];
  onlyOneGroup: boolean = false;
  cssPropertiesEnum = CssPropertiesEnum;
  constructor(
    public translationService: TranslationService,
    public vHostService: VHostService,
    private offerService: OfferService,
    private navigationService: NavigationService,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.isLoadingOfferGroup = true;
    this.offerService.getOfferGroups().subscribe(offerGroups => {
        this.offerGroups = offerGroups;
        if (offerGroups.length == 1) {
          this.goToOfferModule(offerGroups[0].key, 0);
        } else {
          this.offerService.getMinimalOffers(true).subscribe(offers => {
            offerGroups.forEach(group => {
                group.hasOffers = offers.some(offer => offer.offerGroup === group.key);
                if (!group.hasOffers) {
                  console.log('No offers for groupId ' + group.key);
                }
            });
            this.isLoadingOfferGroup = false;
          }, error => {
            this.errorsArr.push(this.translationService.getTranslation('error_offerGroup_init'));
          });
        }
    }, error => {
      this.errorsArr.push(this.translationService.getTranslation('error_offer_init'));
    });
  }

  ngOnInit(): void {
  }

  goToOfferModule(offerGroup, moreThanOneGroup): void {
    this.navigationService.navigateTo(NavigationCommand.OFFERLIST, [offerGroup, moreThanOneGroup]);
  }

  ngAfterViewInit(): void {

  }

}
