import {Component, OnInit} from '@angular/core';
import {TranslationService} from '../../core/services/translation.service';
import {HupSubscriptionsService} from '../../data/services/hup-subscriptions.service';
import {ActivatedRoute} from '@angular/router';
import {HupSubscription} from '../../data/models/subscription.model';
import {NavigationCommand, NavigationService} from "../../data/services/navigation.service";

@Component({
  selector: 'app-hup-subscription',
  templateUrl: './hup-subscription.component.html',
  styleUrls: ['./hup-subscription.component.scss']
})
export class HupSubscriptionComponent implements OnInit {
  subs: HupSubscription[] = [];
  isLoading: boolean;
  errorsArr: string[] = [];

  constructor(public translationService: TranslationService,
              private hupSubscriptionsService: HupSubscriptionsService,
              private route: ActivatedRoute,
              private navigationService: NavigationService) {}

  ngOnInit(): void {
    this.isLoading = true;
    this.hupSubscriptionsService.getSubscriptions().subscribe(subs => {
      this.isLoading = false;
      this.subs = subs;
      if (subs && subs.length === 1) {
        this.aboDetails(this.subs[0]);
      }
    }, error => {
      this.isLoading = false;
      this.errorsArr.push(this.translationService.getTranslation('error_subscription_init'));
    });
  }

  cancelOrder(sub): void {
    alert('cancelOrder');
  }

  // Unterscheiden sich die irgendwie?
  // inProcessDetails(sub): void {
  //   this.router.navigate([+sub.webId + '/' + +sub.backendId], {relativeTo: this.route});
  // }
  aboDetails(sub): void {
    this.navigationService.navigateTo(NavigationCommand.SUBSCRIPTION, [sub.webId, sub.backendId]);
  }

  // changeAboDetails(sub): void {
  //   alert('changeAboDetails');
  // }

}
