<ng-container [formGroup]="controlGroup">
  <ng-container formGroupName = "address"
    *ngIf="controlGroup.get('address.country').value.key !== 'A' && controlGroup.get('address.country').value !== 'A'">
    <mat-form-field class="col-12 col-md-6 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_street')"></mat-label>
      <input matInput formControlName="street" required [matAutocomplete]="matStreetNames"
             (focusin)="searchAddressEmit.emit()">
      <mat-autocomplete #matStreetNames="matAutocomplete">
        <mat-option *ngFor="let street of streetNames" [value]="street">
          {{street}}
        </mat-option>
      </mat-autocomplete>
      <mat-error
        *ngIf="controlGroup.get('address.street').hasError('message')">{{controlGroup.get('address.street').getError('message')}}</mat-error>
    </mat-form-field>
    <mat-form-field class="col-12 col-md-6 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_houseno')"></mat-label>
      <input matInput formControlName="houseNo" (keyup)="replaceInvalidCharactersFromHouseNo()" required>
      <mat-error
        *ngIf="controlGroup.get('address.houseNo').hasError('message')">{{controlGroup.get('address.houseNo').getError('message')}}</mat-error>
    </mat-form-field>
  </ng-container>

  <ng-container formGroupName = "address"
    *ngIf="controlGroup.get('address.country').value === 'A' || controlGroup.get('address.country').value.key === 'A'">
    <mat-form-field class="col-12 col-md-6 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_street')"></mat-label>
      <input matInput formControlName="street" required [matAutocomplete]="matStreetNames" (focusin)="searchAddressEmit.emit()">
      <mat-autocomplete #matStreetNames="matAutocomplete">
        <mat-option *ngFor="let street of streetNames" [value]="street">
          {{street}}
        </mat-option>
      </mat-autocomplete>
      <mat-error
        *ngIf="controlGroup.get('address.street').hasError('message')">{{controlGroup.get('address.street').getError('message')}}</mat-error>
    </mat-form-field>
    <mat-form-field class="col-3 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_houseno')"></mat-label>
      <input matInput formControlName="houseNo" required>
      <mat-error
        *ngIf="controlGroup.get('address.houseNo').hasError('message')">{{controlGroup.get('address.houseNo').getError('message')}}</mat-error>
    </mat-form-field>
    <mat-form-field class="col-3 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_stock')"></mat-label>
      <input matInput formControlName="stock">
      <mat-error
        *ngIf="controlGroup.get('address.stock').hasError('message')">{{controlGroup.get('address.stock').getError('message')}}</mat-error>
    </mat-form-field>
    <mat-form-field class="col-3 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_stiege')"></mat-label>
      <input matInput formControlName="stiege">
      <mat-error
        *ngIf="controlGroup.get('address.stiege').hasError('message')">{{controlGroup.get('stiege').getError('message')}}</mat-error>
    </mat-form-field>
    <mat-form-field class="col-3 ps-2">
      <mat-label [innerHTML]="translationService.getTranslation('address_tuer')"></mat-label>
      <input matInput formControlName="tuer">
      <mat-error
        *ngIf="controlGroup.get('address.tuer').hasError('message')">{{controlGroup.get('address.tuer').getError('message')}}</mat-error>
    </mat-form-field>
  </ng-container>
</ng-container>
