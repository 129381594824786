import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable, of} from 'rxjs';
import {OfferGroup} from '../models/offer-group.model';
import {Offer} from '../models/offer.model';
import {Globals} from '../../core/globals';
import {ImageService} from "../../core/services/image.service";
import {DomSanitizer} from "@angular/platform-browser";
import {Bonus} from "../models/bonus.model";

@Injectable({providedIn: 'root'})
export class OfferService {
  private offers: Offer[] = [];
  private minimal: Offer[] = [];
  private offerGroups: OfferGroup[] = [];
  loadedAllOffers = false;
  loadedAllMinimalOffers = false;


  constructor(private http: HttpClient,
              private imageService: ImageService,
              private sanitizer: DomSanitizer) {
  }

  getMinimalOffers(loadImages: boolean = false) {
    if (this.loadedAllMinimalOffers) {
      return of(this.minimal);
    } else {
      return this.loadOffers(true, loadImages);
    }
  }

  getOffers(): Observable<Offer[]> {
    if (this.loadedAllOffers) {
      return of(this.offers);
    } else {
      /*
      const offerData = JSON.parse(localStorage.getItem('offers'));
      if (offerData && new Date(offerData.expirationDate) > new Date()) {
        this.offers = offerData.offers;
        return of(this.offers);
      }
       */
      return this.loadOffers(false, true);
    }
  }

  private loadOffers(loadMinimal: boolean = false, loadImages: boolean = false) {
    const url = loadMinimal ? '/webabo/offers/minimal' : '/webabo/offers';
    return this.http.get<Offer[]>(url).pipe(map(offers => {
      if (loadImages) {
        this.loadImagesOfOffer(offers);
      }

      if (loadMinimal) {
        this.loadedAllMinimalOffers = true;
        this.minimal = offers;
      } else {
        this.offers = offers;
        this.loadedAllOffers = true;
        const expirationDate = new Date();
        expirationDate.setSeconds(expirationDate.getSeconds() + Globals.CACHE_TIME_BASE_DATA);
        localStorage.setItem('offers', JSON.stringify({expirationDate, offers}));
      }
      return offers;
    }));
  }

  private loadImagesOfOffer(offers: Offer[]) {
    offers.forEach(offer => {
      this.imageService.getImageOfOffer(offer.offerId).subscribe(image => {
        if (image && image.type && image.file) {
          offer.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:' + image.type + ';base64,' + image.file);
        }
      });
      this.imageService.getConfirmationImageOfOffer(offer.offerId).subscribe(image => {
        if (image && image.type && image.file) {
          offer.confirmationImage = this.sanitizer.bypassSecurityTrustResourceUrl('data:' + image.type + ';base64,' + image.file);
        }
      });
      const newBonusList = [];
      offer.bonusList?.forEach((bonus: any) => {
        const newBonus = new Bonus(bonus.id ?? bonus.articleCode, bonus.name, bonus.description, -1, -1, -1, bonus.available, -1, bonus.hasImage, bonus.img, null, 1);
        this.imageService.getImageOfArticle(newBonus.articleCode).subscribe(image => {
            if (image && image.type && image.file) {
              newBonus.img = this.sanitizer.bypassSecurityTrustResourceUrl('data:' + image.type + ';base64,' + image.file);
              newBonus.hasImage = true;
            }
          }
        )
        newBonusList.push(newBonus);
      })
      offer.bonusList = newBonusList;
    });
  }

  getOfferDetail(id: number): Observable<Offer> {
    for (const offer of this.offers) {
      if (offer.offerId === id) {
        return of(offer);
      }
    }
    return this.http.get<Offer>('/webabo/offers/' + +id).pipe(map(offer => {
      if (offer) {
        this.loadImagesOfOffer([offer]);
        this.offers.push(offer);
        return offer;
      }
    }));
  }

  getOfferGroups(): Observable<OfferGroup[]> {
    /*
    const offerGroupsData = JSON.parse(localStorage.getItem('offerGroups'));
    if (offerGroupsData && new Date(offerGroupsData.expirationDate) > new Date()) {
      this.offerGroups = offerGroupsData.offerGroups;
      return of(this.offerGroups);
    }
     */
    return this.http.get<OfferGroup[]>('/webabo/offers/groups').pipe(map(offerGroups => {
      this.offerGroups = offerGroups;
      offerGroups.forEach(group => {
        this.imageService.getImageOfOfferGroup(group.key).subscribe(image => {
          if (image) {
            group.image = this.sanitizer.bypassSecurityTrustResourceUrl('data:' + image.type + ';base64,' + image.file);
          }
        });
      })

      const expirationDate = new Date();
      expirationDate.setSeconds(expirationDate.getSeconds() + Globals.CACHE_TIME_BASE_DATA);
      localStorage.setItem('offerGroups', JSON.stringify({expirationDate, offerGroups}));
      return this.offerGroups;
    }));

  }

}
