import {Component, OnInit} from '@angular/core';
import {HupSubscriptionsService} from '../../data/services/hup-subscriptions.service';
import {TranslationService} from '../../core/services/translation.service';
import {SubChangeService} from '../../data/services/sub-change.service';
import {HupSubscription} from '../../data/models/subscription.model';
import {ActivatedRoute} from '@angular/router';
import {SubChange} from '../../data/models/sub-change.model';
import {NavigationCommand, NavigationService} from "../../data/services/navigation.service";

@Component({
  selector: 'app-sub-change',
  templateUrl: './sub-change.component.html',
  styleUrls: ['./sub-change.component.scss']
})
export class SubChangeComponent implements OnInit {
  isLoadingSub: boolean = false;
  subs: HupSubscription[] = [];
  errorsArr: string[] = [];

  constructor(private subChangeService: SubChangeService,
              private hupSubscriptionsService: HupSubscriptionsService,
              public translationService: TranslationService,
              private route: ActivatedRoute,
              private navigationService: NavigationService
  ) { }

  ngOnInit(): void {
    this.isLoadingSub = true;

    this.hupSubscriptionsService.getSubscriptions().subscribe(subs => {
      this.isLoadingSub = false;
      this.subs = [];
      subs.forEach(sub => {
        if (sub.validDate.validUntil == null || sub.validDate.validUntil > new Date()) {
          if (!sub.offer.offerType.ePaper) {
            this.subs.push(sub);
          }
        }
      });

      this.subs.forEach( sub => {
        sub.isLoadingSubChange = true;

        this.subChangeService.getSubChanges(sub.backendId).subscribe({
          next: (res) => {
            sub.subChanges = res;
          },
          error: (error) => {
            sub.isLoadingSubChange = false;
            this.errorsArr.push(this.translationService.getTranslation('error_subchanges_init'));
          },
          complete: () => {
            sub.isLoadingSubChange = false;
          }
        });
      });

    }, error => {
      this.isLoadingSub = false;
      this.errorsArr.push(this.translationService.getTranslation('error_subscription_init'));
    });
  }

  onDelete(sub: HupSubscription): void{
    this.subChangeService.deleteSubChange(sub.webId, sub.subId, sub.backendId);
  }

  onNewSubChange(sub: HupSubscription): void {
    this.navigationService.navigateTo(NavigationCommand.SUBCHANGENEW, [sub.webId + '', sub.backendId +''])
  }

  onEdit(sub: HupSubscription, subChange: SubChange): void{
    this.navigationService.navigateTo(NavigationCommand.SUBCHANGEEDIT, [subChange.webId + '', subChange.backendId +'', 'true'])
  }

  onDetails(sub: HupSubscription, subChange: SubChange): void{
    this.navigationService.navigateTo(NavigationCommand.SUBCHANGEEDIT, [subChange.webId + '', subChange.backendId +'', sub.backendId + '', 'false'])
  }
}
