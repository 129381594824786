import {Component, OnInit} from '@angular/core';
import {TranslationService} from '../../core/services/translation.service';
import {LoginService} from '../../core/components/login/login.service';
import {MoveService} from '../../data/services/move.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {BaseDataService} from '../../core/services/base-data.service';
import {Move} from '../../data/models/move.model';
import {ActivatedRoute} from '@angular/router';
import {ErrorService} from '../../core/components/general-errors/error.service';
import {BaseData} from '../../data/models/base-data.model';
import {SnackbarService} from "../../core/services/snackbar.service";

@Component({
  selector: 'app-move',
  templateUrl: './move.component.html',
  styleUrls: ['./move.component.scss']
})
export class MoveComponent implements OnInit {
  errorsArr: string[] = [];
  isLoadingUserData: boolean = false;
  isLoadingMoves: boolean = false;
  userData: any;
  priceChecked: boolean = false;
  priceCheckInProcess: boolean = false;
  saveInProcess: boolean = false;
  isMobile: boolean = false;
  minDate: Date;

  moves: Move[];
  countries: BaseData[] = [];
  salutations: BaseData[] = [];
  titles: BaseData[] = [];

  addressForm: FormGroup;

  movePrice: any;

  constructor(private loginService: LoginService,
              private moveService: MoveService,
              private formBuilder: FormBuilder,
              private baseDataService: BaseDataService,
              private route: ActivatedRoute,
              private errorService: ErrorService,
              public translationService: TranslationService,
              private snackBar: SnackbarService,
  ) {
  }

  ngOnInit(): void {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.countries = this.baseDataService.getBaseData('countrycodes');
    this.salutations = this.baseDataService.getBaseData('clienttypes');
    this.titles = this.baseDataService.getBaseData('titles');

    this.isLoadingUserData = true;
    this.isLoadingMoves = true;

    this.addressForm = this.formBuilder.group({
      address: this.formBuilder.group({
        country: ['', Validators.required],
        zipcode: ['', Validators.required],
        city: ['', Validators.required],
        street: ['', Validators.required],
        houseNo: ['', Validators.required],
        extraLine: [''], //
        houseNoExt: [''],
        stiege: [''],
        stock: [''],
        tuer: [''],
      }),
      person: this.formBuilder.group({
        title: [''],
        salutation: ['', Validators.required],
        firstName: ['', Validators.required],
        lastName: ['', Validators.required],
        initials: [''],
        middlename: [''],
      }),
      validDate: this.formBuilder.group({
        validFrom: ['', Validators.required]
      }),
    });

    this.addressForm.valueChanges.subscribe(()=> {
      this.priceChecked = false;
    });

    this.moveService.getMoves().subscribe({
      next: (moves) => {
        this.isLoadingMoves = false;
        this.moves = moves;
      },
      error: (error) => {
        this.isLoadingMoves = false;
        this.errorsArr.push(this.translationService.getTranslation('error_move_init'));
      }
    });

    this.loginService.getUserData().subscribe(userData => {
      this.moveService.getUser(userData.userBackendId).subscribe( user => {
        this.isLoadingUserData = false;
        this.userData = user;

        let foundSalutation = null;
        this.salutations?.forEach(salutation => {
          if (salutation.key === this.userData.person.salutation) {
            foundSalutation = salutation;
          }
        });

        let foundTitle = null;
        this.titles?.forEach(title => {
          if (title.description === this.userData.person.title) {
            foundTitle = title;
          }
        });
        // Formular vorbefüllen
        this.addressForm.get('person.firstName').patchValue(this.userData.person.firstname);
        this.addressForm.get('person.lastName').patchValue(this.userData.person.lastname);
        this.addressForm.get('person.title').patchValue(foundTitle !== null ? foundTitle : this.userData.person.title);
        this.addressForm.get('person.salutation').patchValue(foundSalutation !== null ? foundSalutation : this.userData.person.salutation);
      });
    });

    this.addressForm.valueChanges.subscribe(() => {
      this.priceChecked = false;
    });
  }

  onCheckMove(): void {
    this.addressForm.markAllAsTouched();
    if (this.addressForm.valid && !this.priceCheckInProcess) {
      this.priceCheckInProcess = true;
      this.errorsArr = [];
      const userMove = this.createMoveObjectFromForm();

      this.moveService.checkMove(userMove).subscribe({
        next: (price) => {
          this.priceChecked = true;
          this.priceCheckInProcess = false;
          this.movePrice = price;
        },
        error: (error) => {
          this.priceCheckInProcess = false;
          this.errorsArr = [];
          this.errorService.handleAPIError([this.addressForm], error.error, this.errorsArr);
        }
      });
    } else {
      this.priceChecked = false;
    }
  }

  onSendMove(): void {
    if (this.priceChecked && !this.priceCheckInProcess) {
      this.saveInProcess = true;
      // this.priceCheckInProcess = true;

      const userMove = this.createMoveObjectFromForm();

      this.moveService.sendMove(userMove).subscribe({
        next: (res) => {
          this.snackBar.openSnackbar(this.translationService.getTranslation("saved"));
          this.moveService.getMoves().subscribe((moves) => {
            this.moves = moves;
          });
        },
        error: (error) => {
          this.errorsArr = [];
          this.errorService.handleAPIError([this.addressForm], error.error, this.errorsArr);
        },
        complete: () => {
          this.priceCheckInProcess = false
          this.saveInProcess = false;
        }
      });
    }
  }

  private createMoveObjectFromForm() {
    const data = JSON.parse(JSON.stringify(this.addressForm.value));
    data.person.salutation = data.person.salutation.key;
    data.person.title = data.person.title?.key;
    data.person.lastname = data.person.lastName;
    data.person.firstname = data.person.firstName;

    data.address.country = data.address.country.key;
    data.address.houseno = data.address.houseNo;

    const userMove = {
      user: {
        person: data.person,
        address: data.address,
        userBackendId: this.userData.userBackendId
      },
      changeDate: data.validDate.validFrom,
      created: data.validDate.validFrom
    }

    return userMove;
  }

}
