import {Component, OnInit} from '@angular/core';
import {OfferService} from '../../data/services/offer.service';
import {TranslationService} from '../../core/services/translation.service';
import {ActivatedRoute, Router} from '@angular/router';
import {Offer} from '../../data/models/offer.model';
import {ImageService} from "../../core/services/image.service";
import {CssPropertiesEnum} from "../../core/models/css-properties.enum";
import {VHostService} from "../../core/services/v-host.service";
import {ConfigService} from "../../core/services/config.service";
import {NavigationCommand, NavigationService} from "../../data/services/navigation.service";
import {LandingPageService} from "../../data/services/landing-page.service";
import {LandingPage} from "../../data/models/landing-page.model";

@Component({
  selector: 'app-hup-offer',
  templateUrl: './offer.component.html',
  styleUrls: ['./offer.component.scss']
})
export class OfferComponent implements OnInit {
  isLoadingOffer: boolean = false;
  offers: Offer[] = [];
  activeGroup = null;
  moreThanOneGroup: boolean = false;
  errorsArr: string[] = [];
  CssPropertiesEnum = CssPropertiesEnum;
  highlightIds: string[] = [];
  actualPage: LandingPage;
  constructor(private offerService: OfferService,
              private imageService: ImageService,
              public translationService: TranslationService,
              private route: ActivatedRoute,
              public vHostService: VHostService,
              public configService: ConfigService,
              private navigationService: NavigationService,
              private landingPageService: LandingPageService,
              private router: Router
              ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(param => {
      this.activeGroup = param.get('offerGroup');
      this.moreThanOneGroup = param.get('moreThanOneGroup') === '1'
    });

    this.isLoadingOffer = true;
    this.offerService.getMinimalOffers(true).subscribe(offers => {
      this.isLoadingOffer = false;
      this.offers = offers;
    }, error => {
      this.isLoadingOffer = false;
      this.errorsArr.push(this.translationService.getTranslation('error_offer_init'));
    });

    this.landingPageService.getPageOfUrl(this.router.url.split('/')[1]).subscribe( page => {
      this.actualPage = page;
    });
  }

  goBackToGroupView(): void {
    this.navigationService.navigateTo(NavigationCommand.OFFER)
  }

}
