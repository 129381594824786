<div class="row">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('payment_edit_intro')"></h4>
  </div>
  <div class="col-12 mb-5" [innerHTML]="translationService.getTranslation('payment_edit_description')">
  </div>
</div>

<div *ngIf="isLoadingPayment" style="text-align: center;">
  <app-loading-spinner></app-loading-spinner>
</div>

<app-general-errors [errors]="errorsArr"></app-general-errors>

<form *ngIf="!isLoadingPayment && errorsArr.length == 0" [formGroup]="paymentEditForm" class="mt-3">
  <div class="row">
    <ng-container formGroupName="bank">
      <mat-form-field class="col-12">
        <mat-label [innerHTML]="translationService.getTranslation('payment_iban')"></mat-label>
        <input matInput formControlName="swiftIBAN" required>
        <mat-error *ngIf="paymentEditForm.get('bank.swiftIBAN').hasError('message')">
          {{paymentEditForm.get('bank.swiftIBAN').getError('message')}}
        </mat-error>
      </mat-form-field>
      <div *ngIf="paymentEditForm.get('bank.swiftIBAN').status === 'PENDING'">
        <app-loading-spinner [type]="'small'"></app-loading-spinner>
      </div>

      <mat-form-field class="col-12">
        <mat-label [innerHTML]="translationService.getTranslation('payment_bic')"></mat-label>
        <input matInput formControlName="swiftBIC" required>
        <mat-error *ngIf="paymentEditForm.get('bank.swiftBIC').hasError('message')">
          {{paymentEditForm.get('bank.swiftBIC').getError('message')}}
        </mat-error>
      </mat-form-field>

      <mat-form-field class="col-12">
        <mat-label [innerHTML]="translationService.getTranslation('payment_bankname')"></mat-label>
        <input matInput formControlName="bankName" required>
        <mat-error *ngIf="paymentEditForm.get('bank.bankName').hasError('message')">
          {{paymentEditForm.get('bank.bankName').getError('message')}}
        </mat-error>
      </mat-form-field>
    </ng-container>
  </div>

  <div class="col-12">
    <button mat-raised-button color="primary" (click)="onSendPayment()" [innerHTML]="translationService.getTranslation('button_send')"></button>
  </div>

</form>

<ng-container *ngIf="subs && subs.length > 0">
  <div class="mt-5" [innerHTML]="translationService.getTranslation('payment_affected_orders')">
  </div>
<ng-container *ngFor="let sub of subs; let i=index" class="mt-5">
  <div class="form-group col-12 mt-5">
    <div class="row">
      <div class="col-6" [innerHTML]="translationService.getTranslation('label_backendid')">
      </div>
      <div class="col-6">
        {{sub.userBackendId}}
      </div>
    </div>
  </div>
  <div class="form-group col-12">
    <div class="row">
      <div class="col-6" [innerHTML]="translationService.getTranslation('order_id')">
      </div>
      <div class="col-6">
        {{sub.backendId}}
      </div>
    </div>
  </div>
  <div class="form-group col-12">
    <div class="row">
      <div class="col-6" [innerHTML]="translationService.getTranslation('period')">
      </div>
      <div class="col-6">
        {{sub.validDate.validFrom | date: translationService.getTranslation('dateFormat')}} -
        {{(sub.validDate.validUntil) ? (sub.validDate.validUntil | date: translationService.getTranslation('dateFormat')) : translationService.getTranslation('subscription_endless')}}
      </div>
    </div>
  </div>
  <hr *ngIf="(i + 1) < subs.length ">
</ng-container>
</ng-container>
