<div class="row">
  <div class="col-12">
    <h4 [innerHTML]="translationService.getTranslation('move_intro')"></h4>
  </div>
  <div class="col-12" [innerHTML]="translationService.getTranslation('move_description')">
  </div>
</div>
<div class="row mt-3">
  <div class="col-12">
    <div *ngIf="isLoadingUserData || isLoadingMoves || saveInProcess " style="text-align: center;">
      <app-loading-spinner></app-loading-spinner>
    </div>
    <ng-container *ngIf="!isLoadingUserData && !isLoadingMoves && !saveInProcess">

      <ng-container *ngIf="moves?.length > 0 ">
        <h5 class="mt-5" [innerHTML]="translationService.getTranslation('move_existingMoveHeadline')"></h5>
        <div class="col-12 mb-3" [innerHTML]="translationService.getTranslation('move_existingMoveIntro')">
        </div>
        <ng-container *ngFor="let move of moves; let i = index">
          <div class="form-group col-12">
            <div class="row">
              <div class="col-6" [innerHTML]="translationService.getTranslation('startdate')">
              </div>
              <div class="col-6">
                {{move.validDate.validFrom | date: translationService.getTranslation('dateFormat')}}
              </div>
            </div>
          </div>
          <div class="form-group col-12">
            <div class="row">
              <div class="col-6" [innerHTML]="translationService.getTranslation('name')">
              </div>
              <div class="col-6">
                {{(move.person.firstname ?? '') + " " + move.person.lastname}}
              </div>
            </div>
          </div>
          <div class="form-group col-12">
            <div class="row">
              <div class="col-6" [innerHTML]="translationService.getTranslation('address_street')">
              </div>
              <div class="col-6">
                {{move.address.street}} {{move.address.houseno | houseNoPipe}}
              </div>
            </div>
          </div>
          <div class="form-group col-12">
            <div class="row">
              <div class="col-6" [innerHTML]="translationService.getTranslation('address_postcode')">
              </div>
              <div class="col-6">
                {{ move.address.zipcode}}
              </div>
            </div>
          </div>
          <div class="form-group col-12">
            <div class="row">
              <div class="col-6" [innerHTML]="translationService.getTranslation('address_city')">
              </div>
              <div class="col-6">
                {{move.address.city}}
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>

      <ng-container>
        <h5 class="mt-5" [innerHTML]="translationService.getTranslation('label_personalData')"></h5>
        <div class="form-group col-12">
          <div class="row">
            <div class="col-6" [innerHTML]="translationService.getTranslation('name')">
            </div>
            <div class="col-6">
              {{userData.person.salutationDescription}} {{userData.person.titleDescription}} {{userData.person.firstname}} {{userData.person.lastname}}
            </div>
          </div>
        </div>
        <ng-container *ngIf="userData.birthday != null">
          <div class="form-group col-12">
            <div class="row">
              <div class="col-6" [innerHTML]="translationService.getTranslation('address_birthday')">
              </div>
              <div class="col-6">
                {{userData.birthday | date: translationService.getTranslation('dateFormat')}}
              </div>
            </div>
          </div>
        </ng-container>
        <div class="form-group col-12">
          <div class="row">
            <div class="col-6" [innerHTML]="translationService.getTranslation('label_backendid')">
            </div>
            <div class="col-6">
              {{userData.userBackendId}}
            </div>
          </div>
        </div>

        <h5 class="mt-5" [innerHTML]="translationService.getTranslation('move_current')"></h5>
        <div class="form-group col-12">
          <div class="row">
            <div class="col-6" [innerHTML]="translationService.getTranslation('address_street')">
            </div>
            <div class="col-6">
              {{userData.address.street}} {{ userData.address.houseno | houseNoPipe}}
            </div>
          </div>
        </div>
        <div class="form-group col-12">
          <div class="row">
            <div class="col-6" [innerHTML]="translationService.getTranslation('address_postcode')">
            </div>
            <div class="col-6">
              {{ userData.address.zipcode}}
            </div>
          </div>
        </div>
        <div class="form-group col-12">
          <div class="row">
            <div class="col-6" [innerHTML]="translationService.getTranslation('address_city')">
            </div>
            <div class="col-6">
              {{userData.address.city}}
            </div>
          </div>
        </div>

        <h5 class="mt-5" *ngIf="userData.phoneList && userData.phoneList.length > 0 ||
                                userData.mobileList && userData.mobileList.length > 0 ||
                                userData.emailList && userData.emailList.length > 0" [innerHTML]="translationService.getTranslation('label_contactData')"></h5>
        <div class="form-group col-12" *ngIf="userData.phoneList && userData.phoneList.length > 0">
          <div class="row">
            <div class="col-6" [innerHTML]="translationService.getTranslation('address_telephone')">
            </div>
            <div class="col-6" *ngFor="let phone of userData.phoneList">
              {{phone.prefixNumber}} / {{phone.telNumber}}
            </div>
          </div>
        </div>
        <div class="form-group col-12" *ngIf="userData.mobileList && userData.mobileList.length > 0">
          <div class="row">
            <div class="col-6" [innerHTML]="translationService.getTranslation('address_mobil')">
            </div>
            <div class="col-6" *ngFor="let mobile of userData.mobileList">
              {{mobile.prefixNumber}} / {{mobile.telNumber}}
            </div>
          </div>
        </div>
        <div class="form-group col-12" *ngIf="userData.emailList && userData.emailList.length > 0">
          <div class="row">
            <div class="col-6" [innerHTML]="translationService.getTranslation('address_email')">
            </div>
            <div class="col-6" *ngFor="let mail of userData.emailList">
              {{mail}}
            </div>
          </div>
        </div>

        <app-general-errors [errors]="errorsArr"></app-general-errors>
        <ng-container *ngIf="!moves || moves?.length == 0">
          <h5 class="mt-5" [innerHTML]="translationService.getTranslation('label_newAddress')"></h5>
          <app-hup-subscription-address [showActivateCheckbox]="false"
                                        [controlGroup]="addressForm"
                                        [showValidFrom]="true"
                                        [isMobile]="isMobile"
                                        [minDate]="minDate">
          </app-hup-subscription-address>

          <div class="col-12" *ngIf="priceChecked">
            <ng-container *ngFor="let price of movePrice">
              <ng-container *ngIf="price.priceChange; else noChange">
                Der Preis für den Auftrag {{price.subscriptionId}} hat sich von {{ price.currentPrice}} auf {{price.newPrice}} geändert.
              </ng-container>
              <ng-template #noChange>
                <div>
                  {{price.subscriptionId}}: Der Preis Ihrer Abonnements bleibt durch den Umzug unverändert.
                </div>
              </ng-template>
            </ng-container>
          </div>

          <div class="col-12 mt-3">
            <button class="me-3" mat-raised-button color="primary" (click)="onCheckMove()"
                    *ngIf="!priceChecked && !priceCheckInProcess" [innerHTML]="translationService.getTranslation('button_check_price_move')"></button>
            <button mat-raised-button color="primary" (click)="onSendMove()"
                    *ngIf="priceChecked && !priceCheckInProcess" [innerHTML]="translationService.getTranslation('button_send')"></button>

            <div *ngIf="priceCheckInProcess " style="text-align: center;">
              <app-loading-spinner></app-loading-spinner>
            </div>
          </div>

        </ng-container>
      </ng-container>
    </ng-container>
  </div>
</div>
